import React from "react";
import Globals from "Class/Globals";
import IconButton from "Components/UI/IconButton";

class ToolbarImage extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Dialog = false;
        this.Mounted = false;
        this.UploadDialog = false;
    }

    componentDidMount()
    {
        this.Mounted = true;
    }

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Callback when the button is clicked.
     * @return void
     */

    OnBrowse = (e) =>
    {
        this.Dialog = Globals.DialogCreate({
            title: "Pick image",
            type: "gallery",
            props:
            {
                ref: d => this.UploadDialog = d,
                multiple: false,
                onClose: this.OnClose,
                onSelect: this.OnSelect
            }
        });
    }

    /**
     * Abort all uploads when the dialog is closed.
     * @return void
     */

    OnClose = () =>
    {
        if (this.UploadDialog)
        {
            this.UploadDialog.OnAbort();
        }
    }

    /**
     * Callback when and image has been selected.
     * @param array images - An array of image objects.
     * @return void
     */

    OnSelect = (images) =>
    {
        const {onChange} = this.props;
        Globals.DialogDestroy(this.Dialog);
        if (!this.Mounted || !images.length)
        {
            return;
        }
        onChange(images[0].static.large);
    }

    render()
    {
        const {currentState, onChange} = this.props;
        return (
            <div className="WywiwygToolbarImage WywiwygToolbarButtonWrapper" onClick={e => e.stopPropagation()}>
                <IconButton
                    className="WywiwygToolbarButton"
                    feather="Image"
                    onClick={this.OnBrowse}
                    title="Insert an image"
                />
            </div>
        );  
    }
}

export default ToolbarImage;