import React from "react";
import ToolbarButtons from "./buttons";

class ToolbarList extends React.Component
{
    render()
    {
        const {currentState} = this.props;
        const {listType} = currentState;
        return (
            <ToolbarButtons
                {...this.props}
                active={listType ? [listType] : false}
                buttons={{
                    unordered: ["List", "Insert unordered list"],
                    ordered: ["Hash", "Insert ordered list"] 
                }}
                wrapper={true}
            />
        );
    }
}

export default ToolbarList;