import React from "react";
import IconButton from "Components/UI/IconButton";

class ToolbarButtons extends React.Component
{
    Active = (key) =>
    {
        const {active, currentState} = this.props;
        return currentState[key] || (Array.isArray(active) && active.indexOf(key) >= 0);
    }

    Disabled = (key) =>
    {
        const {disabled} = this.props;
        return disabled === true || (Array.isArray(disabled) && disabled.indexOf(key) >= 0);
    }

    OnClick = (e, key) =>
    {
        const {currentState, onChange, onClick} = this.props;
        if (typeof onClick === "function")
        {
            onClick(e, key);
        }
        else
        {
            onChange(key, !currentState[key]);
        }
    }

    render()
    {
        const {buttons, currentState, wrapper} = this.props;
        const Buttons = [];
        for (let key in buttons)
        {
            Buttons.push(
                <IconButton
                    active={this.Active(key)}
                    className="WywiwygToolbarButton"
                    disabled={this.Disabled(key)}
                    feather={buttons[key][0]}
                    id={key}
                    key={key}
                    onClick={this.OnClick}
                    title={buttons[key][1]}
                />
            );
        }
        return (
            <div className={wrapper ? "WywiwygToolbarButtonWrapper" : "WywiwygToolbarButtons"}>
                {Buttons}
            </div>
        );
    }
}

ToolbarButtons.defaultProps =
{
    active: false,
    buttons: {},
    disabled: false,
    onChange: () => {},
    wrapper: false
};

export default ToolbarButtons;