import React, {Fragment} from "react";
import Globals from "Class/Globals";
import {ColorToString, RandomToken, StripTags} from "Functions";
import ArticleBlockExpandables from "Components/View/Articles/Blocks/Library/Expandables";
import ArticleBlockGallery from "Components/View/Articles/Blocks/Library/Gallery";
import ArticleBlockHtml from "Components/View/Articles/Blocks/Library/Html";
import ArticleBlockLinks from "Components/View/Articles/Blocks/Library/Links";
import ArticleBlockTabs from "Components/View/Articles/Blocks/Library/Tabs";
import ArticleBlockText from "Components/View/Articles/Blocks/Library/Text";
import ArticleBlockTop from "Components/View/Articles/Blocks/Library/Top";
import ArticleBlockVideo from "./Library/Video";
import BlockEditor from "Components/UI/BlockEditor";

class ArticleBlocks extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Templates = {
            top:
            {
                icon: "CreditCard",
                name: "Top"
            },
            text:
            {
                icon: "AlignLeft",
                name: "Text"
            },
            gallery:
            {
                icon: "Image",
                name: "Carousel"
            },
            tabs:
            {
                icon: "Folder",
                name: "Tabs"
            },
            expandables:
            {
                icon: "Maximize2",
                name: "Accordions"
            },
            video:
            {
                icon: "Youtube",
                name: "Video"
            },
            html:
            {
                icon: "Code",
                name: "HTML"
            },
            links:
            {
                icon: "Link",
                name: "Links"
            }
        };
    }

    Appearance = (appearance, id) =>
    {
        const {
            appearance: defaultApperance,
            publish
        } = this.props;
        const {
            colorBackground: cb1,
            colorItemBackground: cib1,
            colorItemForeground: cif1,
            colorText: ct1,
            fontFaceHeading: fh1,
            fontFaceText: ft1,
            inheritAppearance = true,
        } = appearance;
        if (id && inheritAppearance)
        {
            return "";
        }
        const {
            colorBackground: cb2,
            colorItemBackground: cib2,
            colorItemForeground: cif2,
            colorText: ct2,
            fontFaceHeading: fh2,
            fontFaceText: ft2
        } = defaultApperance;
        const FontFaceHeading = fh1 || fh2 || "lato";
        const FontFaceText = ft1 || ft2 || "lato";
        const ColorBackground = this.StyleBackgroundColor(cb1 || cb2);
        const ColorText = ct1 || ct2;
        const ColorItemBackground = this.StyleBackgroundColor(cib1 || cib2);
        const ColorItemForeground = cif1 || cif2;
        const Postfix = id ? "-" + id : "";
        Globals.LoadFont(FontFaceHeading);
        Globals.LoadFont(FontFaceText);
        return (
            <style key={publish}>
                {`
                .cb${Postfix}
                {
                    color: ${ColorText || "#000000"};
                    font-family: ${Globals.FontStr[FontFaceText] || Globals.FontStr["lato"]};
                }

                .cb${Postfix}
                {
                    background: ${ColorBackground || "#ffffff"};
                }

                .cb${Postfix} h1,
                .cb${Postfix} h2,
                .cb${Postfix} h3,
                .cb${Postfix} h4,
                .cb${Postfix} h5,
                .cb${Postfix} h6,
                .cb${Postfix} h1 *,
                .cb${Postfix} h2 *,
                .cb${Postfix} h3 *,
                .cb${Postfix} h4 *,
                .cb${Postfix} h5 *,
                .cb${Postfix} h6 *
                {
                    font-family: ${Globals.FontStr[FontFaceHeading] || Globals.FontStr["lato"]} !important;
                }

                .cb${Postfix} .cb-i
                {
                    color: ${ColorItemForeground || "#ffffff"};
                    background: ${ColorItemBackground || "#40c1ac"};
                }
                `}
            </style>
        );
    }

    GetBlockComponent = (template) =>
    {
        switch (template)
        {
            case "expandables":
                return ArticleBlockExpandables;
            case "gallery":
                return ArticleBlockGallery;
            case "html":
                return ArticleBlockHtml;
            case "links":
                return ArticleBlockLinks;
            case "tabs":
                return ArticleBlockTabs;
            case "text":
                return ArticleBlockText;
            case "top":
                return ArticleBlockTop;
            case "video":
                return ArticleBlockVideo;
            default:
                return ArticleBlockText;
        }
    }

    GetFonts = () =>
    {
        const {appearance, blocks} = this.props;
        const {fontFaceHeading, fontFaceText} = appearance;
        const Fonts = [];
        if (fontFaceHeading)
        {
            Fonts.push(fontFaceHeading);
        }
        if (fontFaceText && fontFaceText !== fontFaceHeading)
        {
            Fonts.push(fontFaceText);
        }
        blocks.forEach(({appearance}) =>
        {
            const {fontFaceHeading, fontFaceText, inheritAppearance} = appearance;
            if (inheritAppearance)
            {
                return;
            }
            if (fontFaceHeading && Fonts.indexOf(fontFaceHeading) < 0)
            {
                Fonts.push(fontFaceHeading);
            }
            if (fontFaceText && Fonts.indexOf(fontFaceText) < 0)
            {
                Fonts.push(fontFaceText);
            }
        });
        return Fonts;
    }

    Imports = () =>
    {
        const {imports, publish} = this.props;
        // Only import when rendering to string.
        if (!imports)
        {
            return;
        }
        const Fonts = [];
        const Token = RandomToken(6);
        const UsedFonts = this.GetFonts();
        UsedFonts.forEach(name =>
        {
            if (Globals.FontSrc[name] === undefined)
            {
                return;
            }
            const {provider, src} = Globals.FontSrc[name];
            switch (provider)
            {
                case "Google":
                    const [A, B] = src.split(":");
                    const Name = A.replace(/ /g, "+");
                    const Weights = B.split(",");
                    const Italics = [];
                    const Regular = [];
                    Weights.forEach(weight =>
                    {
                        if (weight.match(/i/))
                        {
                            Italics.push("1," + weight.replace(/[^0-9]/g, ""));
                        }
                        else
                        {
                            Regular.push("0," + weight.replace(/[^0-9]/g, ""));
                        }
                    });
                    Fonts.push(`@import url("https://fonts.googleapis.com/css2?family=${Name}:ital,wght@${Regular.concat(Italics).join(";")}&display=swap");`);
                    break;
                default:
            }
        });
        return (
            <Fragment key={publish}>
                <script src={Globals.Setting("ApiUrl") + "/jquery-3.7.1.min.js"}></script>
                <script src={Globals.Setting("ApiUrl") + "/gsap.min.js"}></script>
                <script src={Globals.Setting("ApiUrl") + "/block-scripts.js?versionToken=" + Token}></script>
                <style>{Fonts.join("")}</style>
                <link rel="stylesheet" href={Globals.Setting("ApiUrl") + "/blocks.css?versionToken=" + Token}/>
            </Fragment>
        );
    }

    ReadTime = () =>
    {
        return Math.ceil(this.WordCount() / 200);
    }

    RenderBlock = (template, id) =>
    {
        const {
            disabled,
            getBlockById,
            images,
            onBlockEdit,
            onBlockMount,
            onBlockUnmount,
            onImageUrl
        } = this.props;
        const Block = getBlockById(id);
        const CA = ["ViewArticlesContentBlock"];
        if (disabled)
        {
            CA.push("Disabled");
        }
        return React.createElement(this.GetBlockComponent(template),
        {
            appearance: Block?.appearance || {},
            className: CA.join(" "),
            content: Block?.content || {},
            disabled,
            getAppearance: this.Appearance,
            getReadTime: this.ReadTime,
            id,
            images,
            key: id,
            onField: onBlockEdit,
            onImageUrl,
            onMount: onBlockMount,
            onUnmount: onBlockUnmount
        });
    }

    StyleBackgroundColor = (backgroundColor) =>
    {
        return ColorToString(backgroundColor);
    }

    WordCount = () =>
    {
        const {blocks, getBlockById} = this.props;
        let Count = 0;
        blocks.forEach(({id, template}) =>
        {
            const Block = getBlockById(id);
            Count += this.WordCountContent(Block?.content);
        });
        return Count;
    }

    WordCountContent = (content = {}) =>
    {
        let Count = 0;
        for (let key in content)
        {
            if (key === "content" || key === "label" || key === "title")
            {
                Count += this.WordCountString(content[key]);
            }
            else if (key === "items" && Array.isArray(content[key]))
            {
                content[key].forEach(item =>
                {
                    Count += this.WordCountContent(item);
                });
            }
        }
        return Count;
    }

    WordCountString = (str) =>
    {
        return typeof str === "string" ? StripTags(str).split(/[ -]/g).length : 0;
    }

    render()
    {
        const {
            appearance,
            blocks,
            className,
            disabled,
            editable,
            editorClassName,
            fullWidth,
            getBlockById,
            images,
            onBlockDuplicate,
            onBlockInsert,
            onBlockMove,
            onBlockRemove,
            onBlockSelect,
            selected
        } = this.props;
        let Content;
        if (editable)
        {
            Content = (
                <BlockEditor
                    blocks={blocks}
                    className={editorClassName}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    onDuplicateBlock={onBlockDuplicate}
                    onInsertBlock={onBlockInsert}
                    onMoveBlock={onBlockMove}
                    onRemoveBlock={onBlockRemove}
                    onRenderBlock={this.RenderBlock}
                    onSelectBlock={onBlockSelect}
                    selectedIndex={selected}
                    templates={this.Templates}
                />
            );
        }
        else
        {
            const Blocks = [];
            blocks.forEach(({id, template}) =>
            {
                const Block = getBlockById(id);
                const Component = this.GetBlockComponent(template);
                Blocks.push(
                    React.createElement(Component,
                    {
                        appearance: Block?.appearance || {},
                        content: Block?.content || {},
                        editable: false,
                        getAppearance: this.Appearance,
                        getReadTime: this.ReadTime,
                        id,
                        images,
                        key: id
                    })
                );
            });
            Content = (
                <div className="ViewArticlesContentBlocks">
                    {Blocks}
                </div>
            );
        }
        return (
            <div className={className} data-fullwidth={fullWidth ? "1" : "0"}>
                {this.Imports()}
                {this.Appearance(appearance)}
                {Content}
            </div>
        );
    }
}

ArticleBlocks.defaultProps =
{
    appearance: {},
    blocks: [],
    disabled: false,
    editable: true,
    editorClassName: "",
    fullWidth: false,
    getBlockById: () => {},
    images: {},
    imports: false,
    onBlockEdit: () => {},
    onBlockInsert: () => {},
    onBlockMount: () => {},
    onBlockMove: () => {},
    onBlockRemove: () => {},
    onBlockSelect: () => {},
    onBlockUnmount: () => {},
    onImageUrl: () => {},
    publish: 0
};

export default ArticleBlocks;