import React from "react";
import Globals from "Class/Globals";
import Button from "Components/UI/Button";
import ToolbarButtons from "./buttons";

class ToolbarLink extends React.Component
{
    componentWillUnmount()
    {
        Globals.DialogDestroy(this.Dialog);
    }

    EditLink = (title = "", target = "", targetOption = "_blank") =>
    {
        if (!target)
        {
            targetOption = "_blank";
        }
        const Options = {title, target, targetOption};
        this.Dialog = Globals.DialogCreate({
            buttons: target ? (
                <Button
                    hollow={true}
                    label="Remove Link"
                    onClick={this.RemoveLink}
                />
            ) : "",
            confirm: true,
            confirmLabel: target ? "Edit Link" : "Insert Link",
            props: {
                fields: {
                    title: {
                        default: title,
                        type: "text"
                    },
                    target: {
                        default: target || "",
                        placeholder: "https://",
                        type: "text"
                    },
                    targetOption: {
                        default: targetOption === "_blank",
                        text: "Open in a new tab",
                        type: "checkbox"
                    }
                },
                onEdit: (e, key, value) =>
                {
                    switch (key)
                    {
                        case "targetOption":
                            Options[key] = value ? "_blank" : "_top";
                            break;
                        default:
                            Options[key] = value;
                    }
                }
            },
            onConfirm: () =>
            {
                const {onChange} = this.props;
                onChange("link", Options.title, Options.target, Options.targetOption);
            },
            title: target ? "Edit Link" : "Insert Link",
            type: "form"
        });
    }

    OnClick = (e, key) =>
    {
        const {currentState, onChange} = this.props;
        const {link, selectionText} = currentState;
        const {target, targetOption, title} = link || {};
        switch (key)
        {
            case "link":
                this.EditLink(title || selectionText, target, targetOption);
                break;
            case "unlink":
                onChange("unlink", true);
            default:
        }
    }

    RemoveLink = () =>
    {
        Globals.DialogDestroy(this.Dialog);
        const {onChange} = this.props;
        onChange("unlink", true);
    }

    render()
    {
        const {currentState} = this.props;
        return (
            <ToolbarButtons
                {...this.props}
                active={currentState.link ? ["unlink"] : false}
                buttons={{
                    link: ["Link", currentState.link ? "Edit link" : "Insert link"],
                    //unlink: ["XCircle", "Remove link"]
                }}
                disabled={currentState.link ? false : ["unlink"]}
                onClick={this.OnClick}
                wrapper={true}
            />
        );
    }
}

export default ToolbarLink;