import React from "react";
import "./blockeditor.scss";
import Globals from "Class/Globals";
import {CapFloat, RandomToken} from "Functions";
import Icon from "Components/Layout/Icon";
import IconButton from "Components/UI/IconButton";
import ScrollView from "Components/UI/ScrollView";

class BlockEditor extends React.Component
{
    constructor(props)
    {
        super(props);
        this.DragBlock = false;
        this.DragIndex = -1;
        this.DragOrigin = false;
        this.Thresholds = [];
        this.state =
        {
            action: "",
            dragging: false,
            insertIndex: -1,
            keys: {},
            onCanvas: false,
            selected: -1
        };
    }

    componentDidMount()
    {
        const {selectedIndex} = this.props;
        this.SetSelectedIndex(selectedIndex);
    }

    componentDidUpdate(prevProps)
    {
        const {selectedIndex: s1} = this.props;
        const {selectedIndex: s2} = prevProps;
        if (s1 !== s2)
        {
            this.SetSelectedIndex(s1);
        }
    }

    Block = ({id, template}, index) =>
    {
        const {blocks, onRenderBlock} = this.props;
        const {dragging, insertIndex, selected} = this.state;
        const CA = ["ContentBlockContainer"];
        const IndicatorAfter = index + 1 === insertIndex && index !== this.DragIndex && index + 1 !== this.DragIndex;
        const IndicatorBefore = !index && index !== this.DragIndex && !insertIndex;
        if (dragging === id)
        {
            CA.push("IsDragged");
        }
        else if (dragging)
        {
            CA.push("IsPassive");
        }
        if (selected === id)
        {
            CA.push("IsSelected");
        }
        if (IndicatorAfter || IndicatorBefore)
        {
            CA.push("HasIndicator");
        }
        return (
            <div
                className={CA.join(" ")}
                id={id}
                key={id}
                onClick={this.OnBlockSelect}
            >
                <div className="ContentBlockWrapper">
                    <div className="ContentBlockToolbar">
                        <IconButton
                            feather="Move"
                            onMouseDown={this.OnBlockDown}
                            title="Move this block"
                        />
                        <IconButton
                            disabled={!index}
                            feather="ArrowUp"
                            onClick={() => this.OnMoveBlock(index, index - 1)}
                            title="Move this block above the previous block"
                        />
                        <IconButton
                            disabled={index >= blocks.length - 1}
                            feather="ArrowDown"
                            onClick={() => this.OnMoveBlock(index, index + 2)}
                            title="Move this block below the next block"
                        />
                        <IconButton
                            feather="Copy"
                            id={id}
                            onClick={this.OnBlockDuplicate}
                            title="Duplicate this block"
                        />
                        <IconButton 
                            feather="Trash"
                            id={id}
                            onClick={this.OnBlockRemove}
                            title="Remove this block"
                        />
                    </div>
                    {IndicatorBefore ? this.InsertIndicator() : ""}
                    <div className="ContentBlock">
                        {onRenderBlock(template, id)}
                    </div>
                    {IndicatorAfter  ? this.InsertIndicator() : ""}
                </div>
            </div>
        );
    }

    Blocks = () =>
    {
        const {blocks} = this.props;
        if (!blocks.length)
        {
            return (
                <div className="ContentBlocksEmpty">
                    <Icon feather="PlusSquare"/>
                    <div className="ContentBlocksEmptyText">
                        Drop content blocks here
                    </div>
                </div>
            );
        }
        const Blocks = [];
        blocks.forEach((block, index) =>
        {
            Blocks.push(this.Block(block, index));
        });
        return Blocks;
    }

    InsertIndicator = () =>
    {
        const {action} = this.state;
        return (
            <div className="ContentBlockInsert">
                <div className="ContentBlockInsertLine"/>
                <div className="ContentBlockInsertLabel">
                    {action}
                </div>
            </div>
        );
    }

    Key = (slug) =>
    {
        return slug + "-" + (this.state.keys[slug] || 0);
    }

    NextDragScroll = () =>
    {
        if (!this.DragScroll)
        {
            return;
        }
        const Now = (new Date()).getTime();
        if (this.DragScrollLast && this.DragScrollSpeed)
        {
            const Delta = Now - this.DragScrollLast;
            const Current = this.Scroll.Content.scrollTop;
            const Set = CapFloat(Current + this.DragScrollSpeed / Delta * 500, 0, this.DragScrollHeight);
            this.Scroll.Content.scrollTop = Set;
            this.DragScrolled = Set - this.DragScrollInitial;
            if (this.DragBlock && this.LastPos)
            {
                const [DeltaX, DeltaY] = this.LastPos;
                this.DragBlock.style.transform = `translate(${DeltaX}px, ${DeltaY + this.DragScrolled}px)`;
            }
        }
        this.DragScrollLast = Now;
        window.requestAnimationFrame(this.NextDragScroll);
    }

    OnBlockDown = (e) =>
    {
        if (this.props.disabled || e.button !== 0 || !this.Canvas)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const {pageX, pageY} = e;
        const Rect = this.Wrapper.getBoundingClientRect();
        this.DragBlock = e.currentTarget.parentElement.parentElement;
        this.DragIndex = Array.from(this.DragBlock.parentElement.parentElement.childNodes).indexOf(this.DragBlock.parentElement);
        this.DragOrigin = [pageX, pageY];
        this.UpdateBlockThresholds();
        window.addEventListener("mousemove", this.OnBlockMove);
        window.addEventListener("mouseup", this.OnBlockUp);
        this.Canvas.addEventListener("mouseenter", this.OnCanvasEnter);
        this.Canvas.addEventListener("mouseleave", this.OnCanvasLeave);
        this.Canvas.addEventListener("mousemove", this.OnCanvasMove);
        this.setState({
            action: "Move",
            dragging: this.DragBlock.parentElement.id
        });
        this.DragScrolled = 0;
        this.DragScrollInitial = this.Scroll.Content.scrollTop;
        this.DragScrollHeight = this.Scroll.Content.scrollHeight - this.Scroll.Content.offsetHeight;
        this.DragLimit = [Rect.top, Rect.top + Rect.height];
        this.DragScroll = true;
        this.NextDragScroll();
    }

    OnBlockDuplicate = (e, id) =>
    {
        const {blocks, disabled, onDuplicateBlock} = this.props;
        if (disabled)
        {
            return;
        }
        blocks.forEach(({id: blockId}, index) =>
        {
            if (blockId !== id)
            {
                return;
            }
            onDuplicateBlock(id, index);
        });
    }

    OnBlockRemove = (e, id) =>
    {
        Globals.DialogCreate({
            confirmLabel: "Delete Block",
            message: "Are you sure that you want to delete the selected content block?",
            onConfirm: () =>
            {
                const {blocks, onRemoveBlock, onSelectBlock} = this.props;
                const {selected} = this.state;
                if (id === selected)
                {
                    this.OnBlockUnselect();
                }
                blocks.forEach(({id: blockId}, index) =>
                {
                    if (blockId !== id)
                    {
                        return;
                    }
                    onRemoveBlock(index);
                });
            },
            title: "Delete Block",
            type: "confirm"
        });
    }

    OnBlockMove = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        const {pageX, pageY} = e;
        const [originX, originY] = this.DragOrigin;
        const DeltaX = pageX - originX;
        const DeltaY = pageY - originY;
        this.SetDragScroll(pageY);
        this.LastPos = [DeltaX, DeltaY]; 
        this.DragBlock.style.transform = `translate(${DeltaX}px, ${DeltaY + this.DragScrolled}px)`;
    }

    OnBlockSelect = (e) =>
    {
        e.stopPropagation();
        const id = e.currentTarget.id;
        this.setState({selected: id}, () =>
        {
            const {blocks, onSelectBlock} = this.props;
            blocks.forEach(({id: blockId}, index) =>
            {
                if (blockId === id)
                {
                    onSelectBlock(index);
                }
            });
        });
    }

    OnBlockUnselect = () =>
    {
        if (this.state.selected === -1)
        {
            return;
        }
        this.setState({selected: -1}, () =>
        {
            const {onSelectBlock} = this.props;
            onSelectBlock(-1);
        });
    }

    OnBlockUp = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        const {onMoveBlock} = this.props;
        const {insertIndex} = this.state;
        const Move = insertIndex >= 0 && insertIndex !== this.DragIndex && insertIndex !== this.DragIndex + 1 ? [this.DragIndex, insertIndex] : false;
        window.removeEventListener("mousemove", this.OnBlockMove);
        window.removeEventListener("mouseup", this.OnBlockUp);
        this.Canvas.removeEventListener("mouseenter", this.OnCanvasEnter);
        this.Canvas.removeEventListener("mouseleave", this.OnCanvasLeave);
        this.Canvas.removeEventListener("mousemove", this.OnCanvasMove);
        this.setState({
            dragging: false,
            insertIndex: -1,
            onCanvas: false
        }, () =>
        {
            this.DragIndex = -1;
            this.DragOrigin = false;
            if (Move)
            {
                this.DragBlock.style.transform = `translate(0px, 0px)`;
                onMoveBlock(...Move);
            }
            else
            {
                // Maybe add an animation?
                this.DragBlock.style.transform = `translate(0px, 0px)`;
            }
            this.DragBlock = false;
        });
        this.DragBlock = false;
        this.DragScroll = false;
        this.DragScrollLast = false;
        this.DragScrollSpeed = 0;
        this.LastPos = false;
    }

    OnCanvasEnter = () =>
    {
        this.setState({onCanvas: true});
    }

    OnCanvasLeave = () =>
    {
        this.setState({insertIndex: -1, onCanvas: false});
    }

    OnCanvasMove = (e) =>
    {
        let Index = 0;
        for (let i in this.Thresholds)
        {
            if (e.pageY + this.DragScrolled < this.Thresholds[i])
            {
                break;
            }
            Index++;
        }
        if (Index !== this.state.insertIndex)
        {
            this.setState({insertIndex: Index});
        }
    }

    OnMoveBlock = (from, to) =>
    {
        const {onMoveBlock} = this.props;
        onMoveBlock(from, to);
    }

    OnTemplateDown = (e) =>
    {
        if (this.props.disabled || e.button !== 0 || !this.Canvas)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const {pageX, pageY} = e;
        const Rect = this.Wrapper.getBoundingClientRect();
        this.DragBlock = e.currentTarget;
        this.DragOrigin = [pageX, pageY];
        this.UpdateBlockThresholds();
        window.addEventListener("mousemove", this.OnTemplateMove);
        window.addEventListener("mouseup", this.OnTemplateUp);
        this.Canvas.addEventListener("mouseenter", this.OnCanvasEnter);
        this.Canvas.addEventListener("mouseleave", this.OnCanvasLeave);
        this.Canvas.addEventListener("mousemove", this.OnCanvasMove);
        this.setState({
            action: "Insert",
            dragging: e.currentTarget.dataset.slug
        });
        this.DragScrolled = 0;
        this.DragScrollInitial = this.Scroll.Content.scrollTop;
        this.DragScrollHeight = this.Scroll.Content.scrollHeight - this.Scroll.Content.offsetHeight;
        this.DragLimit = [Rect.top, Rect.top + Rect.height];
        this.DragScroll = true;
        //this.NextDragScroll();
    }

    OnTemplateMove = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        const {pageX, pageY} = e;
        const [originX, originY] = this.DragOrigin;
        const DeltaX = pageX - originX;
        const DeltaY = pageY - originY;
        //this.SetDragScroll(pageY);
        this.DragBlock.style.transform = `translate(${DeltaX}px, ${DeltaY}px)`;
    }

    OnTemplateUp = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        const {onInsertBlock} = this.props;
        const {dragging, insertIndex, keys} = this.state;
        const Insert = insertIndex < 0 ? false : [insertIndex, dragging, RandomToken(8)];
        window.removeEventListener("mousemove", this.OnTemplateMove);
        window.removeEventListener("mouseup", this.OnTemplateUp);
        this.Canvas.removeEventListener("mouseenter", this.OnCanvasEnter);
        this.Canvas.removeEventListener("mouseleave", this.OnCanvasLeave);
        this.Canvas.removeEventListener("mousemove", this.OnCanvasMove);
        keys[dragging] = (keys[dragging] || 0) + 1;
        this.setState({
            dragging: false,
            insertIndex: -1,
            keys,
            onCanvas: false
        }, () =>
        {
            this.DragBlock.style.transform = `translate(0px, 0px)`;
            this.DragBlock = false;
            this.DragOrigin = false;
            if (Insert)
            {
                onInsertBlock(...Insert);
            }
        });
        this.DragScroll = false;
        this.DragScrollLast = false;
        this.DragScrollSpeed = 0;
    }

    SetDragScroll = (pageY) =>
    {
        const [up, down] = this.DragLimit;
        const Range = 100;
        const Up = 1 - CapFloat((pageY - up) / Range);
        const Down = 1 - CapFloat((down - pageY) / Range);
        this.DragScrollSpeed = Up ? Up * -1 : Down;
    }

    SetSelectedIndex = (index) =>
    {
        const {blocks} = this.props;
        const Block = blocks[index];
        if (Block === undefined)
        {
            this.setState({selected: -1});
        }
        else
        {
            this.setState({selected: Block.id});
        }
    }

    Template = ({icon, name}, slug) =>
    {
        const {dragging} = this.state;
        return (
            <div className="ContentBlocksTemplateWrapper" key={slug}>
                <div
                    className="ContentBlocksTemplate"
                    onMouseDown={this.OnTemplateDown}
                    data-dragged={slug === dragging ? 1 : 0}
                    data-slug={slug}
                    key={this.Key(slug)}
                >
                    <Icon className="ContentBlocksTemplateIcon" feather={icon}/>
                    <div className="ContentBlocksTemplateLabel">{name}</div>
                </div>
            </div>
        );
    }

    Templates = () =>
    {
        const {templates} = this.props;
        const Templates = [];
        for (let slug in templates)
        {
            Templates.push(this.Template(templates[slug], slug));
        }
        return Templates;
    }

    UpdateBlockThresholds = () =>
    {
        this.Thresholds = [];
        this.Canvas.childNodes.forEach(block =>
        {
            const Rect = block.getBoundingClientRect();
            this.Thresholds.push(Rect.top + Rect.height / 2);
        });
    }

    render()
    {
        const {blocks, children, disabled, className, fullWidth} = this.props;
        const {dragging, onCanvas} = this.state;
        const CA = ["ContentBlocks"];
        if (!blocks.length)
        {
            CA.push("HasNoBlocks");
        }
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (dragging)
        {
            CA.push("IsDragging");
        }
        if (fullWidth)
        {
            CA.push("FullWidth");
        }
        if (onCanvas)
        {
            CA.push("OnCanvas");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")} onClick={this.OnBlockUnselect}>
                {children}
                <div className="ContentBlocksTemplates">
                    {this.Templates()}
                </div>
                <div className="ContentBlocksCanvasWrapper" ref={node => this.Wrapper = node}>
                    <ScrollView className="ContentBlocksCanvasScroll" ref={node => this.Scroll = node}>
                        <div className="ContentBlocksCanvas" ref={node => this.Canvas = node}>
                            {this.Blocks()}
                        </div>
                    </ScrollView>
                </div>
            </div>
        );
    }
}

BlockEditor.defaultProps =
{
    blocks: [],
    className: "",
    disabled: false,
    fullWidth: false,
    onDuplicateBlock: () => {},
    onInsertBlock: () => {},
    onMoveBlock: () => {},
    onRemoveBlock: () => {},
    onRenderBlock: () => {},
    onSelectBlock: () => {},
    selectedIndex: -1,
    templates: {},
};

export default BlockEditor;