/**!
 *  Interactive grid items interface.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./grid.scss";
import {ArrayClone, CapFloat, ObjectCompare, RandomToken} from "Functions";

class Grid extends React.Component
{
    constructor(props)
    {
        super(props);
        this.AdjustDelay = 150;
        this.AdjustTimer = false;
        this.AdjustCallbackTimer = false;
        this.ColumnWidth = 1;
        this.Container = false;
        this.GridWidth = 1;
        this.Ids = [];
        this.Interaction = false;
        this.InteractionDelta = false;
        this.InteractionDims = false;
        this.InteractionItem = false;
        this.InteractionRest = false;
        this.Items = {};
        this.ItemsRefs = {};
        this.LimitX = 0;
        this.LimitY = 0;
        this.Margin = 0;
        this.Width = 0;
        this.Height = 0;
        this.ResizeTimer = false;
        this.Rows = 0;
        this.SortTimer = false;
        this.state =
        {
            active: "",
            adjustedRowHeights: [],
            interaction: "",
            items: [],
            last: "",
            rowInteraction: false,
            rowHeights: [],
            width: 0
        };
    }

    /**
     * Parse items and add listeners on mount.
     * @return void
     */

    componentDidMount()
    {
        const {columns, columnBreaks, items, rowHeights, selected} = this.props;
        const Items = items ? this.ParseItems(items) : false;
        this.SetItems(Items, columns, columnBreaks, rowHeights, true);
        window.addEventListener("resize", this.OnResize);
        window.addEventListener("click", this.ClearSelection);
        this.setState({active: selected});
    }

    /**
     * Parse items when they're updated, or when columns or row height changes.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        if (this.Block)
        {
            return;
        }
        const {columns: c1, columnBreaks: b1, rowHeight: r1, rowHeights: h1, selected: s1, items} = this.props;
        const {columns: c2, columnBreaks: b2, rowHeight: r2, rowHeights: h2, selected: s2} = prevProps;
        const {active: s3, items: currentItems} = this.state;
        const Items = items ? this.ParseItems(items) : false;
        if (   
            // We don't allow the grid to be updated while it's being
            // interacted with.
            !this.Interaction && (
                !ObjectCompare(Items, currentItems) ||
                !ObjectCompare(b1, b2) ||
                c1 !== c2 ||
                r1 !== r2 ||
                !ObjectCompare(h1, h2)
            )
        )
        {
            this.SetItems(Items, c1, b1, h1, true);
        }
        if (!this.Interaction && s1 !== s2 && s1 !== s3)
        {
            this.setState({active: s1});
        }
    }

    /**
     * Remove listeners on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.OnResize);
        window.removeEventListener("click", this.ClearSelection);
    }

    /**
     * Run the adjust callback when the grid changes size.
     * @return void
     */

    Adjust = (width, height) =>
    {
        const {id, onAdjust} = this.props;
        clearTimeout(this.AdjustCallbackTimer);
        this.AdjustCallbackTimer = setTimeout(() =>
        {
            onAdjust(null, [width, height], id);
        }, 100);
    }

    /**
     * Clear current selection.
     * @return void
     */

    ClearSelection = () =>
    {
        if (this.Interaction)
        {
            return;
        }
        const {id, onSelect} = this.props;
        this.setState({active: ""});
        onSelect(null, "", id);
    }

    /**
     * Get number of columns in the grid depending on the grid width.
     * @param integer original - Unaltered number of columns.
     * @param array breaks - Breaking points.
     * @param integer gridWidth - Total width of the grid in pixels.
     * @return integer - Altered number of columns.
     */

    GetColumns = (original, breaks, gridWidth) =>
    {
        const {columns, columnBreaks} = this.props;
        const Columns = original || columns;
        const GridWidth = gridWidth || this.GridWidth;
        const Breaks = breaks || columnBreaks || [];
        let SetColumns = 0;
        Breaks.forEach(([W, C]) =>
        {
            if (SetColumns || W < GridWidth)
            {
                return;
            }
            SetColumns = C;
        });
        return SetColumns || Columns;
    }

    /**
     * Get the height of a row.
     * @param integer index - Row index.
     * @param integer rowHeight - Optional default row height.
     * @param array rowHeights - Optional row height multipliers.
     * @return integer - Row offset in pixels.
     */

    GetRowHeight = (index, rowHeight, rowHeights, adjustHeight) =>
    {
        const {columns, forceFullRowHeightOnNarrowScreens, rowHeight: currentRowHeight, rowHeights: rh2} = this.props;
        const {adjustedRowHeights, rowHeights: rh1} = this.state;
        const RowHeights = adjustHeight ? adjustedRowHeights : (rowHeights || rh1 || rh2);
        let RowHeight = (rowHeight || currentRowHeight) * (RowHeights[index] || 1);
        if (forceFullRowHeightOnNarrowScreens && this.GetColumns() < columns)
        {
            RowHeight = Math.max(rowHeight || currentRowHeight, RowHeight);
        }
        // If rowHeight is < 40, it's interpreted as n column widths.
        // rowHeight >= 40 is interpreted as a pixel size.
        return RowHeight < 40 ? this.ColumnWidth * RowHeight : RowHeight;
    }

    /**
     * Get the total height of a span of rows.
     * @param integer from - First row index.
     * @param integer height - Number of rows.
     * @param integer rowHeight - Optional default row height.
     * @param array rowHeights - Optional row height multipliers.
     * @return integer - Row offset in pixels.
     */

    GetRowSpanHeight = (from, height, rowHeight, rowHeights, adjustHeight) =>
    {
        let Height = 0;
        for (let i = 0; i < height; i++)
        {
            Height += this.GetRowHeight(from + i, rowHeight, rowHeights, adjustHeight);
        }
        return Height;
    }

    /**
     * Get the offset of a widget row.
     * @param integer index - Row index.
     * @param integer rowHeight - Optional default row height.
     * @param array rowHeights - Optional row height multipliers.
     * @return integer - Row offset in pixels.
     */

    GetRowOffset = (index, rowHeight, rowHeights, adjustHeight) =>
    {
        let Offset = 0;
        for (let i = 0; i < index; i++)
        {
            Offset += this.GetRowHeight(i, rowHeight, rowHeights, adjustHeight);
        }
        return Offset;
    }

     /**
     * Count the number of full row heights until a specific row.
     * @param integer index - Row index.
     * @param array rowHeights - Optional row height multipliers.
     * @return integer - Row offset in pixels.
     */

    GetRowOffsetCount = (index, rowHeights) =>
    {
        const {rowHeights: rh2} = this.props;
        const {rowHeights: rh1} = this.state;
        const RowHeights = rowHeights || rh1 || rh2;
        let Count = 0;
        for (let i = 0; i < index; i++)
        {
            Count += RowHeights[i] || 1;
        }
        return Count;
    }

    /**
     * Adjust the width of a widget to make it fit invthe grid.
     * @param integer width - Original width as number of columns.
     * @param integer columns - Number of columns in the grid.
     * @return void
     */

    GetWidth = (width, columns) =>
    {
        const {columns: initialColumns} = this.props;
        // Cap width at grid width.
        // Don't parse if the grid still has its' initial number of columns.
        if (width >= columns)
        {
            return columns;
        }
        // Don't parse if the grid still has its' initial number of columns.
        if (columns === initialColumns)
        {
            return width;
        }
        // If the grid has had its' number of columns reduced because of RWD --
        // make sure its' width is an even divisor of the reduced number of columns.
        let Grow = width;
        let Count = 1;
        while (Grow < columns)
        {
            Grow += width;
            Count++;
        }
        return width - Math.ceil((Grow % columns) / Count);
    }

    /**
     * Arrange all items and seperate a selected item from the rest
     * allowing for seemless transitions during interaction.
     * @param string id - The selected item id. 
     * @return array - Arranged/sorted grid items.
     */

    ExtractItem = (id) =>
    {
        const Items = this.SortedItems(id);
        let Index = -1;
        Items.forEach((item, index) =>
        {
            if (Index >= 0 || item.id !== id)
            {
                return;
            }
            Index = index;
        });
        if (Index < 0)
        {
            return;
        }
        this.InteractionItem = ArrayClone(Items[Index]);
        this.InteractionRest = ArrayClone(Items);
        this.InteractionRest.splice(Index, 1);
        const {width, height, x, y} = this.InteractionItem;
        this.InteractionDelta = [width, height, x, y].join(".");
        return Items;
    }

    /**
     * Output a grid item.
     * @param object item - The item object.
     * @return JSX - The grid item.
     */

    Item = (item) =>
    {
        const {interactive, onTransitionEnd} = this.props;
        const {
            active,
            interaction,
            interactionType,
            last,
            width: containerWidth
        } = this.state;
        const {
            id,
            content,
            height,
            width,
            x, y,
            fill
        } = item;
        const [SX, SY, SW, SH] = interaction === id ? this.InteractionDims || [] : [];
        const CA = ["GridItem"];
        const NumColumns = this.GetColumns();
        let X = this.Margin + x * this.ColumnWidth;
        let Y = this.GetRowOffset(y, false, false, true);
        let W = width * this.ColumnWidth;
        let H = this.GetRowSpanHeight(y, height, false, false, true);
        if (fill && !x)
        {
            X -= this.Margin;
            W += this.Margin;
        }
        if (fill && x + width >= NumColumns)
        {
            W += this.Margin;
        }
        this.Items[id] = {
            x: X,
            y: Y,
            width: W,
            height: H,
            cx: x,
            cy: y,
            cw: width,
            ch: height,
        };
        const Style = {
            width: Math.round(SW === undefined ? W : SW),
            height: Math.round(SH === undefined ? H : SH),
            transform: SX === undefined ? `translate3d(${X}px,${Y}px,0)` : `translate3d(${SX}px,${SY}px,0)`
        };
        if (active === id)
        {
            CA.push("Active");
        }
        if (interaction === id)
        {
            CA.push("Interacting");
            switch (interactionType)
            {
                case 0: CA.push("InteractingMove"); break;
                case 1: CA.push("InteractingResize"); break;
                default:
            }
        }
        if (last === id)
        {
            CA.push("Interacted");
        }
        if (this.Ids.indexOf(id) < 0)
        {
            CA.push("NewItem");
            this.Ids.push(id);
        }
        this.Width = Math.min(containerWidth, Math.max(this.Width, X + W));
        this.Height = Math.max(this.Height, Y + H); 
        this.Rows = Math.max(this.Rows, y + height);
        return (
            <div
                className={CA.join(" ")}
                key={id}
                onTransitionEnd={e => onTransitionEnd(e, id)}
                ref={node => this.ItemsRefs[id] = node}
                style={Style}
            >
                <div className="GridItemWrapper">
                    {interactive ? this.ItemResize(id) : ""}
                    <div className="GridItemContent">
                        {content}
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Output an items' resize handles.
     * @param string id - The item id.
     * @return JSX - The resize handles wrapped in a container.
     */

    ItemResize = (id) =>
    {
        return (
            <div
            
                className="GridItemResize"
                onMouseDown={e => this.OnMoveStart(e, id)}
                onMouseEnter={e => this.OnMouseEnter(e, id)}
                onMouseLeave={e => this.OnMouseLeave(e, id)}
            >
                <div
                    className="GridItemResizeHandle Top Left"
                    onMouseDown={e => this.OnHandleStart(e, id, -1, -1)}
                />
                <div
                    className="GridItemResizeHandle Top Center"
                    onMouseDown={e => this.OnHandleStart(e, id, 0, -1)}
                />
                <div
                    className="GridItemResizeHandle Top Right"
                    onMouseDown={e => this.OnHandleStart(e, id, 1, -1)}
                />
                <div
                    className="GridItemResizeHandle Middle Left"
                    onMouseDown={e => this.OnHandleStart(e, id, -1, 0)}
                />
                <div
                    className="GridItemResizeHandle Middle Right"
                    onMouseDown={e => this.OnHandleStart(e, id, 1, 0)}
                />
                <div
                    className="GridItemResizeHandle Bottom Left"
                    onMouseDown={e => this.OnHandleStart(e, id, -1, 1)}
                />
                <div
                    className="GridItemResizeHandle Bottom Center"
                    onMouseDown={e => this.OnHandleStart(e, id, 0, 1)}
                />
                <div
                    className="GridItemResizeHandle Bottom Right"
                    onMouseDown={e => this.OnHandleStart(e, id, 1, 1)}
                />
            </div>
        );
    }

    /**
     * Callback when an item is moved or resized.
     * @param integer x - Column offset.
     * @param integer y - Row offset.
     * @param integer width - Column span.
     * @param integer height - Row span.
     * @return void
     */

    OnAdjustItem = (x, y, width, height) =>
    {
        if (!this.InteractionItem)
        {
            return;
        }
        const {   
            width: currentWidth,
            height: currentHeight,
            x: currentX,
            y: currentY
        } = this.InteractionItem;
        const Width = width === undefined ? currentWidth : width;
        const Height = height === undefined ? currentHeight : height;
        const X = currentX + x;
        const Y = currentY + y;
        const Delta = [Width, Height, X, Y].join(".");
        if (Delta === this.InteractionDelta)
        {
            return;
        }
        this.InteractionDelta = Delta;
        const Updated = ArrayClone(this.InteractionItem);
        const Items = ArrayClone(this.InteractionRest);
        Items.unshift(Updated);
        Updated.width = Width;
        Updated.height = Height;
        Updated.x = X;
        Updated.y = Y;
        // Wait a short while before adjusting the grid to avoid "bounces" when
        // the user drags an item through it.
        clearTimeout(this.AdjustTimer);
        this.AdjustTimer = setTimeout(() =>
        {
            const Parsed = this.ParseItems(Items);
            this.SetItems(Parsed);
        }, this.AdjustDelay);
    }

    /**
     * Callback when the user stops dragging an item resize handle.
     * @param object e - The event object.
     * @return void
     */

    OnHandleEnd = (e) =>
    {
        if (!this.Interaction)
        {
            return;
        }
        clearTimeout(this.AdjustTimer);
        const {sortDelay} = this.props;
        const {interaction} = this.state;
        e.stopPropagation();
        e.preventDefault();
        window.removeEventListener("mousemove", this.OnHandleMove);
        window.removeEventListener("mouseup", this.OnHandleEnd);
        document.removeEventListener("mouseleave", this.OnHandleEnd);
        this.Interaction = false;
        this.InteractionDelta = false;
        this.InteractionDims = false;
        this.InteractionItem = false;
        this.InteractionRest = false;
        this.Block = true;
        this.ResetItem(interaction);
        this.SortItems(0, sortDelay);
        // Temp solution...
        setTimeout(() =>
        {
            this.setState({interaction: "", interactionType: 1}, () => this.Block = false);
            window.addEventListener("click", this.ClearSelection);
        }, 500);
    }

    /**
     * Callback when the user stops drags an item resize handle.
     * @param object e - The event object.
     * @return void
     */

    OnHandleMove = (e) =>
    {
        const {disabled, minSize} = this.props;
        if (disabled || !this.Interaction)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const {pageX, pageY} = e;
        const [Item, OX, OY, SX, SY, SW, SH, DX, DY, CX, CY] = this.Interaction || [];
        const _DX = (pageX - OX) * DX;
        const _DY = (pageY - OY) * DY;
        const RowHeight = this.GetRowHeight(CY);
        // If we're resizing from the top or left, the item should move in
        // order to clip the item to its' right/bottom edge.
        let X = DX < 0 ? SX - _DX : SX;
        let Y = DY < 0 ? SY - _DY : SY;
        let W = DX ? SW + _DX : SW;
        let H = DY ? SH + _DY : SH;
        // Adjust dimension if either the new width or height is lesser than
        // the minimum size.
        if (W < minSize)
        {
            X -= DX < 0 ? minSize - W : 0;
            W = minSize;
        }
        if (H < minSize)
        {
            Y -= DY < 0 ? minSize - H : 0;
            H = minSize;
        }
        // Calculate and constrain the items position and size.
        const DC = DX < 0 ? CapFloat(Math.round(_DX / -this.ColumnWidth), this.LimitX[1], this.LimitX[2]) : 0;
        const DR = DY < 0 ? Math.min(this.LimitY[0], Math.round(_DY / -RowHeight)) : 0;
        const DW = CapFloat(Math.round(W / this.ColumnWidth), 1, this.LimitX[0]);
        const DH = DY < 0 ? CapFloat(Math.round(H / RowHeight), 1, this.LimitY[1]) : Math.max(1, Math.round(H / RowHeight));
        this.OnAdjustItem(DC, DR, DW, DH);
        this.InteractionDims = [X, Y, W, H];
        Item.style.width = W + "px";
        Item.style.height = H + "px";
        Item.style.transform = `translate3d(${X}px,${Y}px,0)`;
    }

    /**
     * Callback when the user starts dragging an item resize handle.
     * @param object e - The event object.
     * @param string itemId - The item id.
     * @param integer x - The horisontal resize direction: -1, 0 or 1
     * @param integer y - The vertical resize direction: -1, 0 or 1
     * @return void
     */

    OnHandleStart = (e, itemId, x, y) =>
    {
        const {disabled, id, onSelect} = this.props;
        if (disabled || e.button !== 0 || !this.ItemsRefs[itemId])
        {
            return;
        }
        const {pageX, pageY} = e;
        const {cw, ch, cx, cy, x: currentX, y: currentY, width, height} = this.Items[itemId] || {};
        const Columns = this.GetColumns();
        e.stopPropagation();
        e.preventDefault();
        this.Interaction = [
            this.ItemsRefs[itemId],
            pageX,
            pageY,
            currentX || 0,
            currentY || 0,
            width || 0,
            height || 0,
            x, y,
            cx, cy
        ];
        // Calculate the maximum width of this item to constrain it.
        this.LimitX = [ x < 0 ? cw + cx : Columns - cx, -cx, cw - 1];
        this.LimitY = y < 0 ? [ch - 1, ch + cy] : 0;
        const Items = this.ExtractItem(itemId);
        this.setState({
            active: itemId,
            interaction: itemId,
            interactionType: 1,
            items: Items,
            last: itemId
        });
        window.removeEventListener("click", this.ClearSelection);
        window.addEventListener("mousemove", this.OnHandleMove);
        window.addEventListener("mouseup", this.OnHandleEnd);
        document.addEventListener("mouseleave", this.OnHandleEnd);
        onSelect(e, itemId, id);
    }

    /**
     * Callback when the cursor enters a grid item.
     * @param object e - The event object.
     * @param string itemId - The item id.
     * @return void
     */

    OnMouseEnter = (e, itemId) =>
    {
        const {disabled, id, onMouseEnter} = this.props;
        if (disabled)
        {
            return;
        }
        onMouseEnter(e, itemId, id);
    }

    /**
     * Callback when the cursor leaves a grid item.
     * @param object e - The event object.
     * @param string itemId - The item id.
     * @return void
     */

    OnMouseLeave = (e, itemId) =>
    {
        const {disabled, id, onMouseLeave} = this.props;
        if (disabled)
        {
            return;
        }
        onMouseLeave(e, itemId, id);
    }

    /**
     * Callback when the user drags an item.
     * @param object e - The event object.
     * @return void
     */

    OnMove = (e) =>
    {
        const {disabled} = this.props;
        if (disabled || !this.Interaction)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const {pageX, pageY} = e;
        const [Item, OX, OY, SX, SY, CX, CY] = this.Interaction || [];
        const DX = pageX - OX;
        const DY = pageY - OY;
        // Calculate and constrain the items position.
        const DC = CapFloat(Math.round(DX / this.ColumnWidth), this.LimitX[0], this.LimitX[1]);
        const DR = Math.max(this.LimitY, Math.round(DY / this.GetRowHeight(CY)));
        this.OnAdjustItem(DC, DR);
        const X = SX + DX;
        const Y = SY + DY;
        this.InteractionDims = [X, Y];
        Item.style.transform = `translate3d(${X}px,${Y}px,0)`;
    }

    /**
     * Callback when the user stops dragging an item.
     * @param object e - The event object.
     * @return void
     */

    OnMoveEnd = (e) =>
    {
        if (!this.Interaction)
        {
            return;
        }
        clearTimeout(this.AdjustTimer);
        const {sortDelay} = this.props;
        const {interaction} = this.state;
        e.stopPropagation();
        e.preventDefault();
        window.removeEventListener("mousemove", this.OnMove);
        window.removeEventListener("mouseup", this.OnMoveEnd);
        document.removeEventListener("mouseleave", this.OnMoveEnd);
        this.Interaction = false;
        this.InteractionDelta = false;
        this.InteractionDims = false;
        this.InteractionItem = false;
        this.InteractionRest = false;
        this.Block = true;
        this.ResetItem(interaction);
        this.SortItems(0, sortDelay);
        // Temp solution...
        setTimeout(() =>
        {
            this.setState({interaction: "", interactionType: false}, () => this.Block = false);
            window.addEventListener("click", this.ClearSelection);
        }, 500);
    }

    /**
     * Callback when the user starts dragging an item.
     * @param object e - The event object.
     * @param string itemId - The item id.
     * @return void
     */

    OnMoveStart = (e, itemId) =>
    {
        const {disabled, id, onSelect} = this.props;
        if (disabled || e.button !== 0 || !this.ItemsRefs[itemId])
        {
            return;
        }
        const {pageX, pageY} = e;
        const {cw, cx, cy, x, y} = this.Items[itemId] || {};
        const Columns = this.GetColumns();
        e.stopPropagation();
        e.preventDefault();
        this.Interaction = [this.ItemsRefs[itemId], pageX, pageY, x || 0, y || 0, cx || 0, cy || 0];
        // Calculate the maximum offset of this item to constrain it.
        this.LimitX = [-cx, Columns - cx - cw];
        this.LimitY = -cy;
        const Items = this.ExtractItem(itemId);
        this.setState({
            active: itemId,
            interaction: itemId,
            interactionType: 0,
            items: Items,
            last: itemId
        });
        window.removeEventListener("click", this.ClearSelection);
        window.addEventListener("mousemove", this.OnMove);
        window.addEventListener("mouseup", this.OnMoveEnd);
        document.addEventListener("mouseleave", this.OnMoveEnd);
        onSelect(e, itemId, id);
    }

    /**
     * Refresh the grid when the client resizes.
     * @return void
     */

    OnResize = () =>
    {
        const {autoAdjust, items} = this.props;
        if (!autoAdjust)
        {
            return;
        }
        // Set/reset a timeout to avoid hammering.
        clearTimeout(this.ResizeTimer);
        this.ResizeTimer = setTimeout(() =>
        {
            const Items = items ? this.ParseItems(items) : false;
            this.SetItems(Items);
        }, 100);
    }

    OnRowHandleEnd = (e) =>
    {
        if (!this.Interaction)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        window.removeEventListener("mousemove", this.OnRowHandleMove);
        window.removeEventListener("mouseup", this.OnRowHandleEnd);
        document.removeEventListener("mouseleave", this.OnRowHandleEnd);
        const {id, onRowHeights} = this.props;
        const {rowHeights} = this.state;
        const [Handle] = this.Interaction;
        this.Interaction = false;
        this.setState({interactionType: false, rowInteraction: false});
        Handle.style.transform = `translateY(0px)`;
        setTimeout(() => window.addEventListener("click", this.ClearSelection), 0);
        onRowHeights(e, ArrayClone(rowHeights), id);
    }

    OnRowHandleMove = (e) =>
    {
        const {disabled, rowHeightInterval} = this.props;
        const {items, rowInteraction, rowHeights} = this.state;
        if (disabled || !this.Interaction)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const {pageY} = e;
        const [Handle, OY, MinY, MaxY, Height, Step, PreviousHeight] = this.Interaction;
        let Y = CapFloat(pageY - OY, MinY, MaxY);
        const Steps = Math.round(Y / Step);
        const NewHeight = Height + Steps * rowHeightInterval;
        Y -= Steps * Step;
        if (!PreviousHeight)
        {
            this.Interaction[6] = NewHeight;
        }
        else if (PreviousHeight !== NewHeight)
        {
            rowHeights[rowInteraction] = NewHeight;
            this.Interaction[6] = NewHeight;
            const Items = items ? this.ParseItems(items) : false;
            this.SetItems(Items, false, false, rowHeights, true);
        }
        Handle.style.transform = `translateY(${Y}px)`;
    }

    /**
     * Callback when the user starts dragging a row resize handle.
     * @param object e - The event object.
     * @param intege index - The row index.
     * @return void
     */

    OnRowHandleStart = (e, index) =>
    {
        const {disabled, rowHeight, rowHeightInterval, rowHeightMin} = this.props;
        const {rowHeights} = this.state;
        const {currentTarget, pageY} = e;
        if (disabled || e.button !== 0)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        // If rowHeight is < 40, it's interpreted as n column widths.
        // rowHeight >= 40 is interpreted as a pixel size.
        const RowHeight = rowHeight < 40 ? this.ColumnWidth * rowHeight : rowHeight;
        const CurrentHeight = rowHeights[index] || 1;
        // 0.5 is the min row height.
        //const MinY = Math.round((CurrentHeight - 0.5) * -RowHeight);
        const MinY = Math.round((CurrentHeight - rowHeightMin) * -RowHeight);
        // The highest value below 2 is the the max row height.
        // Raise to 10?
        const MaxY = Math.round((10 - rowHeightInterval - CurrentHeight) * RowHeight);
        const StepY = RowHeight * rowHeightInterval;
        this.setState({interactionType: 2, rowInteraction: index});
        this.Interaction = [currentTarget, pageY, MinY, MaxY, CurrentHeight, StepY];
        window.removeEventListener("click", this.ClearSelection);
        window.addEventListener("mousemove", this.OnRowHandleMove);
        window.addEventListener("mouseup", this.OnRowHandleEnd);
        document.addEventListener("mouseleave", this.OnRowHandleEnd);
    }

    /**
     * Adjust the position and size of each grid item in order fit them into
     * the grid. The previous item pushes the next item.
     * @param object items - Input items.
     * @param boolean place - Wheter to find an appropriate placement of each item.
     * @return object - Parsed items.
     */

    ParseItems = (items, place = true) =>
    {
        const {rowHeights} = this.props;
        const Columns = this.GetColumns();
        const Items = ArrayClone(items);
        const Grid = [];
        if (place)
        {
            Items.forEach(item =>
            {
                const {id, width, height, x, y} = item;
                const [X, Y, W, H] = this.PlaceItem(x, y, width || 1, height || 1, Grid);
                item.fullWidth = width >= Columns;
                item.rowHeight = rowHeights[y] || 1;
                item.id = id || RandomToken();
                item.width = W;
                item.height = H;
                item.x = X;
                item.y = Y;
            });
        }
        return Items;
    }

    /**
     * Call this method recursively until we find a place for this item
     * in the grid.
     * @param integer x - Desired column offset.
     * @param integer y - Desired row offset.
     * @param integer width - Column span.
     * @param integer height - Row span.
     * @param array grid - The grid object.
     * @param integer offsetX - Column offset from the initial position.
     * @param integer offsetY - Row offset from the initial position.
     * @return array - Adjusted position and dimensions as [x, y, width, height]
     */

    PlaceItem = (x = 0, y = 0, width, height, grid, offsetX = 0, offsetY = 0) =>
    {
        const Columns = this.GetColumns();
        const X = (x + offsetX) % Columns;
        const Y = y + offsetY;
        const Width = this.GetWidth(width, Columns);
        let Fits = true;
        for (let r = Y; r < Y + height; r++)
        {
            // Create an empty row if it doesn't exist in the grid.
            if (!grid[r])
            {
                grid[r] = [];
                for (let c = 0; c < Columns; c++)
                {
                    grid[r].push(0);
                }
            }
            if (X + Width > Columns)
            {
                Fits = false;
            }
            else
            {
                for (let c = X; c < X + Width; c++)
                {
                    if (grid[r][c] !== 0)
                    {
                        Fits = false;
                        break;
                    }
                }
            }
            if (!Fits)
            {
                break;
            }
        }
        // Try a new position if the item doesn't fit.
        if (!Fits)
        {
            // Try the next column, if it's avaiable. The column offset wraps
            // around in order to try each available column on each row.
            if (offsetX < Columns - 1)
            {
                return this.PlaceItem(x, y, width, height, grid, offsetX + 1, offsetY);
            }
            // Test the closest untested row.
            // Move upwards if there is a previously untested row that is
            // closer than the nearest, untested downward row.
            else if (offsetY >= 0 && y - offsetY > 0)
            {
                return this.PlaceItem(x, y, width, height, grid, 0, -offsetY - 1);
            }
            // In all other cases, move downwards until it fits.
            else
            {
                return this.PlaceItem(x, y, width, height, grid, 0, offsetY < 0 ? offsetY * -1 : offsetY + 1);
            }
        }
        // Mark this items' position in the grid once it fits.
        for (let r = Y; r < Y + height; r++)
        for (let c = X; c < X + Width; c++)
        {
            grid[r][c] = 1;
        }
        return [X, Y, Width, height];
    }

    /**
     * Reset an item to its' last registered size and position.
     * @param string id - The items' id.
     * @return void.
     */

    ResetItem = (id) =>
    {
        const {x, y, width, height} = this.Items[id] || {};
        const Item = this.ItemsRefs[id];
        if (!Item || x === undefined)
        {
            return;
        }
        Item.style.width = width + "px";
        Item.style.height = height + "px";
        Item.style.transform = `translate3d(${x}px,${y}px,0)`;
    }

    /**
     * Render an interactive row handle.
     * @param integer index - Row index.
     * @return object - Parsed items.
     */

    RowHandle = (index) =>
    {
        const {rowInteraction, rowHeights} = this.state;
        const CA = ["GridRowHandle"];
        if (index === rowInteraction)
        {
            CA.push("Highlight");
        }
        else if (rowInteraction !== false)
        {
            CA.push("Hide");
        }
        const Y = this.GetRowOffset(index + 1);
        return (
            <div
                className={CA.join(" ")}
                key={index}
                style={{top: Y + "px"}}
                onMouseDown={e => this.OnRowHandleStart(e, index)}
            >
                {rowHeights ? <div className="GridRowHandleLabel"><span>{(rowHeights[index] || 1).toFixed(2)}</span></div> : ""}
            </div>
        );
    }

    /**
     * Refresh the grid.
     * @param object items - Optional input items.
     * @param integer columns - Number of grid columns.
     * @param integer rowHeight - Grid row height.
     * @param boolean noCallback - Don't trigger onChange().
     * @return object - Parsed items.
     */

    SetItems = (items, columns, columnBreaks, rowHeights, noCallback) =>
    {
        const {
            columns: currentColumns,
            columnBreaks: currentColumnBreaks,
            id,
            maxWidth,
            onChange
        } = this.props;
        const Columns = columns || currentColumns;
        const ColumnBreaks = columnBreaks || currentColumnBreaks;
        const TotalWidth = (this.Container ? this.Container.offsetWidth : 0) || 1;
        const GridWidth = maxWidth ? Math.min(maxWidth, TotalWidth) : TotalWidth;
        const SetColumns = this.GetColumns(Columns, ColumnBreaks, this.GridWidth = GridWidth);
        this.ColumnWidth = 1 / SetColumns * GridWidth;
        // If a maxWidth has been defined, calculate the margin on each side of
        // the grid.
        this.Margin = (TotalWidth - GridWidth) / 2;
        if (!items)
        {
            this.setState({items, rowHeights, width: TotalWidth});
        }
        else
        {
            const State = {items, adjustedRowHeights: [], width: TotalWidth};
            const RowHeights = [];
            items.forEach(({rowHeight, y}) =>
            {
                State.adjustedRowHeights[y] = Math.max(rowHeight, State.adjustedRowHeights[y] || 0);
            });
            if (rowHeights)
            {
                State.rowHeights = rowHeights;
            }
            this.setState(State);
            if (!noCallback)
            {
                onChange(null, items, id);
            }
        }
    }

    /**
     * Sort items by active id and position.
     * @param string id - Optional item id to prioritize.
     * @return array - Sorted items.
     */

    SortedItems = (id) =>
    {
        const {items} = this.state;
        const Items = ArrayClone(items);
        Items.sort((a, b) =>
        {
            if (id && a.id === id) return -1;
            if (id && b.id === id) return 1;
            if (a.y < b.y) return -1;
            if (b.y > a.y) return 1;
            if (a.x < b.x) return -1;
            if (b.x > a.x) return 1;
            return 0;
        });
        return Items;
    }

    /**
     * Sort items by active id and position and then update the component
     * state after a delay.
     * @param string itemId - Optional item id to prioritize.
     * @param integer delay - Delay in ms before sorting.
     * @return void.
     */

    SortItems = (itemId, delay = 0) =>
    {
        const {id, onChange, onChanged} = this.props;
        clearTimeout(this.SortTimer);
        this.SortTimer = setTimeout(() =>
        {
            const Items = this.SortedItems(itemId);
            this.setState({items: Items});
            onChange(null, Items, id);
            onChanged(null, Items, id);
        }, delay);
    }

    render()
    {
        const InitialW = this.Width;
        const InitialH = this.Height;
        this.Width = 0;
        this.Height = 0;
        const {className, disabled, emptyText, interactive, interactiveRows} = this.props;
        const {interaction, items, rowInteraction} = this.state;
        const CA = ["Grid"];
        const Items = [];
        const RowHandles = [];
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (interactive)
        {
            CA.push("Interactive");
            if (interaction || rowInteraction !== false)
            {
                CA.push("Interacting");
            }
            else
            {
                CA.push("NotInteracting")
            }
        }
        if (!items.length)
        {
            CA.push("Empty");
            Items.push(<div className="GridEmpty" key="empty">{emptyText}</div>);
        }
        else
        {
            items.forEach(item => Items.push(this.Item(item)));
        }
        if (interactive && interactiveRows)
        {
            for (let i = 0; i < this.Rows; i++)
            {
                RowHandles.push(this.RowHandle(i));
            }
        }
        if (className)
        {
            CA.push(className);
        }
        const Style = items.length ? {width: this.Width, height: this.Height} : {};
        // I don't like having a callback inside render() but here we go.
        if (InitialW !== this.Width || InitialH !== this.Height)
        {
            this.Adjust(this.Width, this.Height);
        }
        return (
            <div className={CA.join(" ")}>
                <div className="GridWrapper" ref={node => this.Container = node}>
                    <div className="GridItems" style={Style}>
                        {Items}
                        {RowHandles}
                    </div>
                </div>
            </div>
        );
    }
}

Grid.propTypes =
{
    autoAdjust: PropTypes.bool,
    className: PropTypes.string,
    columns: PropTypes.number,
    columnBreaks: PropTypes.array,
    disabled: PropTypes.bool,
    emptyText: PropTypes.string,
    forceFullRowHeightOnNarrowScreens: PropTypes.bool,
    id: PropTypes.string,
    interactive: PropTypes.bool,
    interactiveRows: PropTypes.bool,
    items: PropTypes.array,
    maxWidth: PropTypes.number,
    minSize: PropTypes.number,
    onAdjust: PropTypes.func,
    onChange: PropTypes.func,
    onChanged: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onSelect: PropTypes.func,
    onTransitionEnd: PropTypes.func,
    rowHeight: PropTypes.number,
    rowHeightInterval: PropTypes.number,
    rowHeights: PropTypes.array,
    selected: PropTypes.string,
    sortDelay: PropTypes.number
};

Grid.defaultProps =
{
    autoAdjust: true,
    className: "",
    columns: 3,
    columnBreaks: [],
    disabled: false,
    emptyText: "",
    forceFullRowHeightOnNarrowScreens: false,
    interactive: true,
    interactiveRows: true,
    items: [],
    maxWidth: 0,
    minSize: 100,
    onAdjust: () => {},
    onChange: () => {},
    onChanged: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onRowHeights: () => {},
    onSelect: () => {},
    onTransitionEnd: () => {},
    rowHeight: 1,
    rowHeightInterval: 0.25,
    rowHeightMin: 0.5,
    rowHeights: [],
    selected: "",
    sortDelay: 300
};

export default Grid;